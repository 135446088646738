import React from "react"
import GradientJumbo from "../../components/gradient-jumbo"
import Layout from "../../components/layout"
import Contact from "../../components/sections/contact"
import ToolsAndResourceCards from "../../components/sections/ferramentas-e-recursos/tools_and_resources_cards"
import Seo from "../../components/seo"

const ToolsAndResourcesPage = props => (
  <Layout language="en" pathname={props.location.pathname} hasGlobeBg={true}>
    <Seo
      title="Tools & Resources"
      lang="en"
      description="In this page you can find CAMARADES tools"
    />
    <GradientJumbo subtitle="Tools & Resources" title="Some tools CAMARADES" />
    <div className="container">
      <ToolsAndResourceCards language='en' />
    </div>
    <Contact language="en" />
  </Layout>
)

export default ToolsAndResourcesPage
